/* global sessionStorage crypto */

/**
 * Generates a PKCE (Proof Key for Code Exchange) code challenge.
 *
 * - Creates a random `codeVerifier` with 43 length to match PKCE spec requirement
 * - Stores it in `sessionStorage`
 * - Hashes the `codeVerifier` using SHA-256
 * - Converts the hash to a Base64URL-encoded string (code challenge)
 *
 * @returns {Promise<{codeChallenge: string}>} A promise resolving to the code challenge.
 */
const generatePKCECodeChallenge = () => {
  const codeVerifierLength = 43
  const codeVerifier = Array.from(crypto.getRandomValues(new Uint8Array(32)))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
    .slice(0, codeVerifierLength)

  sessionStorage.setItem('codeVerifier', codeVerifier)

  const codeVerifierAsUint8 = new TextEncoder().encode(codeVerifier)

  return crypto.subtle.digest('SHA-256', codeVerifierAsUint8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const base64UrlHash = btoa(String.fromCharCode(...hashArray))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')

    return { codeChallenge: base64UrlHash }
  })
}

export default generatePKCECodeChallenge
