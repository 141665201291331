import en from '../locales/en.json'

const translations = { en }

// Default language
let currentLanguage = 'en'

export const setLanguage = (lang) => {
  if (translations[lang]) {
    currentLanguage = lang
  }
}

export const t = (key, params = {}) => {
  const keys = key.split('.')
  let translation = keys.reduce((obj, k) => obj?.[k], translations[currentLanguage]) || key

  // Replace placeholders
  Object.keys(params).forEach((param) => {
    translation = translation.replace(`{${param}}`, params[param])
  })

  return translation
}
