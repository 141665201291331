import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import PageHeading from '@components/PageHeading/PageHeading'
import Form from '@components/pages/Merchant/Form'
import toast from '@utils/toast'
import CREATE_MERCHANT from '@graphql/mutations/create-merchant'
import GET_MERCHANT from '@graphql/queries/get-merchant'
import GET_MERCHANTS from '@graphql/queries/get-merchants'
import { t } from '@utils/translations'

function New() {
  const navigate = useNavigate()

  const [createMerchant, result] = useMutation(CREATE_MERCHANT, {
    onCompleted(data) {
      if (data.createMerchant) {
        navigate(`/merchants/${data.createMerchant.id}`)
        toast(t('messages.merchantUpdated'))
      }
    },
    refetchQueries: [
      { query: GET_MERCHANTS, variables: { filters: {} } }
    ],
    update(cache, { data: { createMerchant } }) {
      cache.writeQuery({
        query: GET_MERCHANT,
        variables: { id: createMerchant.id },
        data: { merchant: createMerchant }
      })
    }
  })

  const onSubmit = (fields) => {
    createMerchant({ variables: fields })
  }

  return (
    <>
      <PageHeading heading='New merchant' />
      <Form
        onSubmit={onSubmit}
        result={result}
        submitButtonLabel='Create merchant'
      />
    </>
  )
}

export default New
