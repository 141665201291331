import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import Spinner from 'react-bootstrap/Spinner'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Form from '@components/pages/Merchant/Form'
import toast from '@utils/toast'
import GET_MERCHANT from '@graphql/queries/get-merchant'
import UPDATE_MERCHANT from '@graphql/mutations/update-merchant'
import { t } from '@utils/translations'

function Edit() {
  const { merchantId } = useParams()
  const navigate = useNavigate()
  const merchant = useQuery(GET_MERCHANT, { variables: { id: merchantId } })

  const [updateMerchant, result] = useMutation(UPDATE_MERCHANT, {
    onCompleted(data) {
      navigate(`/merchants/${data.updateMerchant.id}`)
      toast(t('messages.merchantUpdated'))
    }
  })

  const onSubmit = (fields) => {
    updateMerchant({
      variables: { id: merchantId, ...fields }
    })
  }

  return (
    <>
      <PageHeading heading='Edit merchant' />

      {merchant?.error && <Error error={merchant.error} />}
      {merchant?.loading && (
        <div className='d-flex justify-content-center'>
          <Spinner animation='border' role='status' className='my-2' />
        </div>
      )}

      {!merchant?.error && !merchant?.loading && (
        <Form
          merchant={merchant?.data?.merchant}
          onSubmit={onSubmit}
          result={result}
          submitButtonLabel='Update merchant'
        />
      )}
    </>
  )
}

export default Edit
